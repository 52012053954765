<template>
  <div class="background">
    <div class="headBar">
      <div class="headBar_child">
        <div class="request" @click="this.$router.push('/RequestDetail')" style="cursor: pointer">提出需求</div>
        <div class="service" @click="this.$router.push('/ServiceDetail')" style="cursor: pointer">提供服务</div>
      </div>
    </div>
    <div class="main_body">
      <!--必填-->
      <div>
        <div class="title">
          <!--        <i></i>-->
          <p>内容服务详情</p>
        </div>
        <div class="formRequired">
          <div>
            <p>服务联系人</p>
            <input type="text" v-model="this.form.linkman">
          </div>
          <div>
            <p>联系电话</p>
            <input type="text" v-model="this.form.phone">
          </div>
          <div>
            <p>服务类型</p>
            <input type="text" v-model="this.form.type">
          </div>
          <div>
            <p>服务方式</p>
            <input type="text" v-model="this.form.serverWay">
          </div>
          <div>
            <p>服务部分</p>
            <input type="text" v-model="this.form.serverPart">
          </div>
          <div style="height: 2.15rem">
            <p>擅长描述部分</p>
            <input style="height: 2.15rem" type="text" v-model="this.form.advantage">
          </div>
          <div class="enclosure">
            <p>相关附件</p>
            <button>提交附件</button>
          </div>
        </div>
      </div>
      <!--补充-->

      <div class="button_box">
        <button @click="submit">提交发布</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestDetail",
  data(){
    return{
        form:{
          pid:'',
          linkman:'',
          phone:'',
          type:'',
          serverWay:'',
          serverPart:'',
          advantage:'',
          annex:''
        }
    }
  },
  methods:{
    submit(){
      this.form.pid = this.$route.query.pid;

      //将数据转化为formData格式
      let formData = new FormData();
      formData.append('pid',this.form.pid);
      formData.append('linkman',this.form.linkman);
      formData.append('phone',this.form.phone);
      formData.append('type',this.form.type);
      formData.append('serverWay',this.form.serverWay);
      formData.append('serverPart',this.form.serverPart);
      formData.append('advantage',this.form.advantage);
      formData.append('annex',this.form.annex);

      this.$http({
        method: 'post',
        url: '/applyProject',
        data: formData,
      }).then(res => {
        this.$notify({
          title: '操作成功',
          message: '',
          type: 'success'
        });
        this.$router.push('/Home');
      });
    }
  },
}
</script>

<style scoped>

p{
  margin-bottom: 0;
}

input{
  font-size: .24rem!important;
  color:#212121!important;
  padding-left: .25rem!important;
}

.background{
  height: 17.12rem;
  position: relative;
  background-image: url("../../images/req_bac.png");
  background-size: 100% 100%;
  padding-top: 1.28rem;
}

.main_body{
  margin-left: 2.57rem;
}

.headBar{
  width: 100%;
  height: .92rem;
  font-size: .32rem;
  font-weight: normal;
  margin-bottom: .83rem;
  border: .2rem #3c3c3c;

}

.headBar_child{
  width: 7.2rem;
  height: 100%;
  margin: 0 auto;

}

.headBar .service{
  float: left;
  background-color: rgb(235,66,77);
  color: #ffffff;
  width: 3.6rem;
  height: .92rem;
  line-height: .92rem;
  /*display: table-cell;*/
  text-align: center;
  font-size: .32rem;
  border: .02rem solid rgb(235,66,77);
  border-radius: .03rem;

}

.headBar .request{
  float: left;
  color: #212121;
  width: 3.6rem;
  height: .92rem;
  /*display: table-cell;*/
  /*align-items: center;*/
  text-align: center;
  line-height: .92rem;
  font-size: .32rem;
  border: .02rem solid #dcdcdc;
  border-radius: .03rem;
}

.title {
  /*float: none;*/
  width: 100%;
  height: .62rem;
  line-height: .62rem;
  margin-bottom: .62rem;
  font-weight:500;
}

.title p{
  float: left;
  margin-bottom: 0;
}

.title p{
  font-size: .45rem;
  color: #000000;
}

.title p::before{
  content: '';
  display: block;
  float: left;
  width: .12rem;
  height: .64rem;
  border-radius: .05rem;
  margin-right: .42rem;
  margin-left: -0.5rem;
  background-color: #ec414d;
}

.button_box{
  position: absolute;
  left: 11.62rem;
  bottom: .86rem;
}

.button_box button{
  width: 1.93rem;
  height: .68rem;
  font-size: .24rem;
  font-weight: 500;
  text-align: center;
  line-height: .68rem;
  color: #fef6f6;
  background-color: #ec414d;
  border-radius: .03rem;
  cursor: pointer;
  background-image: linear-gradient(115deg,transparent 50%,#212121 50%);
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: right bottom;
  transition: all linear .7s;
  margin-right: .35rem;
}

.button_box button:hover{
  background-size: 300%;
}


.formRequired p{
  width: 1.98rem;
  height: .32rem;
  float: left;
  font-size: .32rem;
  /*margin-right: .7rem;*/
  color: #222222;
}


.formRequired div{
  height: .8rem;
  margin-bottom: .38rem;
  line-height: .8rem;
}

.formRequired input{
  width: 11.3rem;
  height: .8rem;
  border: .01rem solid #dddddd;
  border-radius: .05rem;

}

.formRequired .start input{
  display: block;
  float: left;
  width: 2.14rem;
  height: .8rem;
  margin-right: .68rem;
}

.formRequired .start p:nth-of-type(2){
  width: 1.28rem;
  margin-right: .1rem;
}

.formRequired .start p:nth-of-type(3){
  width: 1.28rem;
  margin-right: .1rem;
}



.supplement{
  height: 5.4rem;
  margin-top: .16rem;
}

.supplement .title{
  font-size: .45rem;
  color: #000000;
  font-weight:500;
}

.formAddition div{
  /*height: .8rem;*/
  /*background-color: pink;*/
  margin-bottom: .38rem;
  line-height: .8rem;
}

.formAddition p{
  float: left;
  width: 1.98rem;
  height: .32rem;
  /*line-height: .32rem;*/
  font-size: .32rem;
  color: #222222;
}

.formAddition input{
  width: 11.3rem;
  height: .8rem;
  border: .01rem solid #dddddd;
  border-radius: .05rem;
  /*background-color: pink;*/
}

.formAddition i{
  height: .8rem;
  line-height: .8rem;
  font-size: .32rem;
  color: #222222;
  margin-left: .03rem;
  margin-top: .02rem;
}


.formAddition .demand input{
  width: 11.3rem;
  height: 2.14rem;
  border: .01rem solid #dddddd;
  border-radius: .05rem;
}

.enclosure{
  /*float: left;*/
  /*width: 1.23rem;*/
  height: .6rem;
  margin-top: .1rem;
  margin-bottom: 0;
}

.enclosure button{
  width: 1.23rem;
  height: .6rem;
  background-color: #ff645f;
  border-radius: .05rem;
  font-size: .18rem;
  color: #ffffff;
}


</style>
